.svgHeader {
    width: 100%;
    height: 60px;
}

.svgFooter {
    width: 100%;
    height: 40px;
}

.appContainer {
}

.header {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 60px;
}

.footer {
    width: 100%;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    height: 40px;
    margin-bottom: -20px;
    border: 1px solid #00000033;
}

.footerLink {
    text-decoration: none;
    color: black;
}
.footerLink svg {
    transform: translateY(6px);
}