.modelContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stateMachineContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 580px;
    padding: 20px;
}

.stateMachineContainer button {
    margin: 10px;
}

.licenseText {
    white-space: pre-wrap;
    background-color: #f8f8f8;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.5;
    width: fit-content;
}

.contractLink {
    border: 1px solid #ddd;
    padding: 4px;
    font-family: monospace;
}

.explorerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.status {
    cursor: default;
    margin-top: 10px;
}

.status:hover {
    text-decoration: underline;
}

.online {
    color: green;
}

.offline {
    color: red;
}

.sessionTime {
    margin-top: 5px;
    font-weight: bold;
}

.logsTable {
    border-collapse: collapse;
}

.logsTable tbody {
    display: table-row-group; /* Default value to keep table layout */
}

.logsTable th, .logsTable td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    font-family: monospace;
}

.logsTable th {
    background-color: #f2f2f2;
    position: sticky;
    top: 0; /* Keep the header on top */
}

.removedLog {
    background-color: #ff6347; /* Lighter tomato color for removed logs */
}

.truncated {
    cursor: pointer;
}

.truncated:hover {
    max-width: none; /* Allow expansion on hover without a fixed max-width */
    background-color: #f0f0f0; /* Optional: highlight on hover */
}

.logsTable tr:hover {
    background-color: #f0f0f0; /* Light grey color for highlighting */
}

.logsContainer {
    overflow-y: auto;
    height: 312px;
}

.eventLogTopic {
    font-family: monospace;
    font-size: 14px;
    color: #666;
    cursor: pointer;
    margin-bottom: 2px;
}
